import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { MessageSubjectFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import MessageLink from '../MessageLink/MessageLink';
import type { ActionButtonComponentProps } from '../MessageSubjectOverviewModal/MessageSubjectOverviewModal';
import localStyles from './MessageSubject.module.pcss';
import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';

const MessageSubjectOverviewModal = dynamic(
  () => import('../MessageSubjectOverviewModal/MessageSubjectOverviewModal'),
  {
    ssr: false
  }
);

interface Props {
  /**
   * Class name to apply to the component.
   */
  className?: string;
  /**
   * Class name to apply to the admin nav bar.
   */
  subjectLinkClassName?: string;
  /**
   * Message subject fragment
   */
  message: MessageSubjectFragment;
  /**
   * If the subject is also a link.
   */
  useLink?: boolean;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Sets title on component. Useful when text might be truncated.
   */
  useTitle?: boolean;
  /**
   * If the message is to be redirected to draft view instead of published.
   */
  isDraftView?: boolean;
  /**
   * the message subject to be highlighted (To be only used for displaying search results)
   */
  subjectSearchSnippet?: string;
  /**
   * Whether the `message.searchSnippet` property should be used for displaying the subject,
   * otherwise the `message.subject` is used.
   */
  useSearchSnippet?: boolean;
  /**
   * Whether to render the Subject Overview Modal or not
   */
  usePreviewSubjectModal?: boolean;
  /**
   * Title for the subject Overview Modal
   */
  previewTitle?: string;
  /**
   * Component to render for the action buttons in modal if needed.
   */
  actionButtonComponent?: React.FC<React.PropsWithChildren<ActionButtonComponentProps>>;
  /**
   * If the subject link should open in new tab. Requires useLink to be true.
   */
  useNewTab?: boolean;
  /**
   * Whether to use manual sort navigation.
   */
  useManualSortNav?: boolean;
  /**
   * Query Params to be added to the message link in the subject overview modal
   */
  queryParams?: ParsedUrlQuery;
}

/**
 * Message subject.
 *
 * @param className - Class name(s) to apply to the component element.
 * @param as - Set a custom element for this component.
 * @param message - The message to display the subject for.
 * @constructor
 *
 * @author Adam Ayres
 */
const MessageSubject: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  subjectLinkClassName,
  as: Component = 'h5',
  message,
  useLink = true,
  useTitle = false,
  isDraftView = false,
  subjectSearchSnippet,
  useSearchSnippet = true,
  usePreviewSubjectModal,
  previewTitle,
  actionButtonComponent: ActionButtonComponent,
  useNewTab = false,
  useManualSortNav,
  queryParams = {}
}: Props) => {
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.MESSAGE_SUBJECT);
  const cx = useClassNameMapper(localStyles);
  const [showModal, setShowModal] = useState<boolean>(false);

  if (textLoading) {
    return null;
  }

  const { subject, uid } = message;

  const highlightedMessageSubject = (
    <span
      className={subjectLinkClassName}
      dangerouslySetInnerHTML={{ __html: subjectSearchSnippet }}
    ></span>
  );

  return (
    <Component
      title={useTitle === true ? subject : null}
      className={cx('text-break', className)}
      data-testid="MessageSubject"
    >
      {useLink ? (
        <MessageLink
          className={subjectLinkClassName}
          message={message}
          isDraftView={isDraftView}
          isNewTab={useNewTab}
          useManualSortNav={useManualSortNav}
        >
          {subjectSearchSnippet && useSearchSnippet
            ? highlightedMessageSubject
            : subject || formatMessage('noSubject', { uid })}
        </MessageLink>
      ) : usePreviewSubjectModal ? (
        <Button
          onClick={() => setShowModal(true)}
          variant={ButtonVariant.NO_VARIANT}
          className={cx('lia-message-subject')}
          data-testid="MessageSubjectButton"
        >
          <span className={cx(subjectLinkClassName)}>{subject}</span>
        </Button>
      ) : (
        <>{subjectSearchSnippet && useSearchSnippet ? highlightedMessageSubject : subject}</>
      )}
      {usePreviewSubjectModal && (
        <MessageSubjectOverviewModal
          message={message}
          show={showModal}
          onHide={(): void => setShowModal(false)}
          actionButtonComponent={ActionButtonComponent}
          previewTitle={previewTitle}
          queryParams={queryParams}
        />
      )}
    </Component>
  );
};

export default MessageSubject;
